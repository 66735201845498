.Hero-heading {
  font-size: 36px;
  font-weight: 400;
}

.Hero-heading--small {
  display: block;
  font-size: 24px;
}


.Hero.jumbotron {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

.Hero-hr {
  background-image: linear-gradient(to right, rgba(51, 102, 0, 0), rgba(51, 102, 0, 0.75), rgba(51, 102, 0, 0));
  border: 0;
  height: 1px;
}

@media screen and (min-width: 768px) {
  .Hero-heading {
    font-size: 48px;
  }
  
  .Hero-heading--small {
    font-size: 36px;
  }
}