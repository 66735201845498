.Track-controlBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  transition: all 0.2s;
}

.Track-controlBtn:hover {
  opacity: 0.8;
}

.Track-icon {
  font-size: 32px;
}
