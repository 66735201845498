.Home {
	flex: 1 0 auto;
}

.Home-category {
  height: 175px;
  cursor: pointer;
}

.Home-categories {
  max-width: 620px;
}
