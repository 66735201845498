.AudioPlayer {
  min-height: 124px;
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
}

.AudioPlayer-controlsBox {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}

.AudioPlayer-controlBtn {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.AudioPlayer-trackDuration {
  max-width: 620px;
  margin: 0 auto;
  width: 100%;
}

.AudioPlayer-duration {
  text-align: center;
}

.AudioPlayer-controlIcon {
  font-size: 32px;
}

.AudioPlayer-slider {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 0.5rem;
}

.AudioPlayer-slider:hover {
  opacity: 1;
}

.AudioPlayer-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: #00cc33;
  cursor: pointer;
  border-radius: 100%;
}

.AudioPlayer-slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #00cc33;
  cursor: pointer;
  border-radius: 100%;
}






@media screen and (min-width: 768px) {

}
