.Playlist {
  height: calc(100% - 56px);
}

.Playlist-trackList {
  overflow: auto;
}

.TrackList {
  overflow: auto;
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
}

.Carousel.carousel {
  display: none;
}

.Carousel.carousel .carousel-inner {
  height: 100%;
}

.Carousel.carousel .Carousel-item,
.Carousel.carousel .Carousel-item img {
  height: 100%;
}

.Loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--success);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (min-width: 768px) {
  .Carousel.carousel {
    display: block;
    height: 270px;
    width: 576px;
    margin: 0 auto;
  }
}