/* ### Global ### */
html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
	background-color: rgb(26, 26, 26);
}

#root { 
  height: 100%;
}

.App {
	height: 100%;
	display: flex;
  flex-direction: column;
}
