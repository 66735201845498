.Navbar-youtube {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.Navbar-hr {
  width: 100%;
}

.Navbar .Navbar-navDropdown .dropdown-menu {
  background-color: inherit;
}

.Navbar-navDropdown.nav-item .dropdown-toggle {
  align-items: center;
  display: flex;
}

.Navbar-youtubeIcon {
  font-size: 48px;
  color: #FF0000;
}

@media screen and (min-width: 768px) {
  .Navbar-youtube {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .Navbar-youtubeIcon {
    font-size: 36px;
  }
  

  .Navbar-hr {
    display: none;
  }
}
